import React from 'react';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';

const QubUserPopper = ({open, anchorEl, placement, children}) => {

    return (
        <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
            {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
                <Paper>
                    {children}
                </Paper>
            </Fade>
            )}
        </Popper>
        )
}

export default QubUserPopper;