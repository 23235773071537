import React from "react";
import ReactDOM from "react-dom";
import Router from "./routing/Router";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme'
import './index.css'

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Router />
    </ThemeProvider>,
  document.getElementById("root")
);