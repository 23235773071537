import {Typography,makeStyles} from '@material-ui/core'
import {TreeView,TreeItem} from '@material-ui/lab'
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';


const useStyles = makeStyles({
    div: {
        display: 'flex',
        'flex-direction': 'column',
        alignItems: 'left',
        flexGrow:1,

    }

})


const Group = (props) => {
    const classes = useStyles()

    return (
        <div className = {classes.div} style = {{back: '#00ff00'}}>
            <TreeView 
                defaultCollapseIcon={<RemoveIcon />}
                defaultExpandIcon={<AddIcon/>}
                defaultExpanded = { ['1'] }
                style = {{back: '#ff0000',height:"100%"}}
                >

                <div className = {classes.div} style = {{back: '#0000ff',height:"100%",wrap:'nowrap'}}>

                    <TreeItem 
                        nodeId="1" 
                        label={<Typography style={{'textAlign':'left','fontSize':14}}>{props.name}</Typography>} 
                        style={{'textAlign':'left','fontSize':12}} >
                        {props.children}
                    </TreeItem>
                </div>
            </TreeView>
        </div>

    )
}


export default Group