import React, { Suspense, lazy } from 'react'
import { useParams } from 'react-router-dom'
import { QubLoading } from '@qubiteq/qublib'

const QubDynamicRouter = () => {
    const { component } = useParams();
    const LazyComponent = lazy(() => import(`components/Dynamic/${component}`))

    return (
        <div>
            <Suspense fallback={<QubLoading />}>
                <LazyComponent />
            </Suspense>
        </div>
    )
}

export default QubDynamicRouter;