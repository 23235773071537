import React from 'react';
import { MenuItem, TextField} from '@material-ui/core';
import { Controller } from "react-hook-form";
import PropTypes from 'prop-types';

const Dropdown = ({ name, control, label, options, rules, setValue, hasValueField,valueField, labelField, textFieldProps,disabled }) => {

    // useEffect(() => {
    //     if(setValue){
    //         const [alreadySelected]=options.filter(option => option.isSelected)
    //         setValue(name, alreadySelected)
    //         console.log(alreadySelected, options)
    //     }
    // }, [])

    const generateSelectOptions = () => {
        if (Array.isArray(options)){
            return options.map((option) => {
                return <MenuItem key={option[valueField]} value={hasValueField ? option[valueField] : option}>
                {option[labelField]}
                </MenuItem>}
            );
        }
        else{
            console.log(options)
            return <div>No</div>
        }
    };

    return (

            <Controller
                
                control={control}
                name={name}
                rules={rules}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return <TextField
                        style={{minWidth:400}}
                        disabled={disabled}
                        label={label}
                        select
                        SelectProps={{
                            id:'dropdown',
                            onChange: onChange,
                            value: value ? value : '',
                            MenuProps:{
                                anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                                },
                                transformOrigin: {
                                vertical: "top",
                                horizontal: "center",
                                },
                                getContentAnchorEl: null,
                            }
                        }}
                        size='small'
                        error={error ? true : false}
                        helperText={error?.message}
                        //fullWidth
                        variant='outlined'
                        {...textFieldProps}
                    >
                        {generateSelectOptions()}
                        
                    </TextField>
                }}
            />
        )
}

Dropdown.propTypes = {
    name: PropTypes.string, 
    control: PropTypes.object, 
    label: PropTypes.string, 
    options: PropTypes.arrayOf(PropTypes.object), 
    valueField: PropTypes.string, 
    labelField: PropTypes.string, 
    textFieldProps: PropTypes.object
}

export default Dropdown