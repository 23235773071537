import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 16,
    fontWeightLight: 50,
    fontWeightRegular: 100,
    fontWeightMedium: 200,
    fontColor: '#ff0000'
  },
 
  palette: {
    // type: 'dark',
    background:{
      default: '#ffffff', //'#121212',
      paper: '#fefefe'//'#383838',
    },
    primary: {
      main: '#34403A',
    },
    secondary: {
      main:'#cf6679'
    },
    text:{
      primary: '#121212',
      color: '#121212'
    }
  },
    
  });

export default theme