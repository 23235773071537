import React, { useEffect, useState } from 'react'
import { Grid, Button, TextField } from '@material-ui/core'
import { QubControls, QubAccess, QubIcon, QubForm, useQubForm } from '@qubiteq/qublib';
import { useHistory } from 'react-router';
import AuthError from '../utils/AuthError'
import {useForm, Controller} from 'react-hook-form'

const Login = ({login, isAuthenticated, home}) => {
    const history = useHistory();
    const [allow, setAllow] = useState(null)
    const { control, handleSubmit } = useForm();
    // const [loading, setLoading] = useState(false)

    useEffect(() => {
        isAuthenticated()
        .then(res => {
            if(res)
                history.goBack();
        })
        .catch(err => {
            if(err instanceof AuthError )
                setAllow(true)
            else
                console.log(err)
        })
    }, [isAuthenticated, history])

    const onSubmit = (values) => {
        // e.preventDefault()
        console.log(values)
        // setLoading(true)
        login(values)
        .then(res => {
            // setLoading(false)
            history.push('/table');
            console.log(res)
        })
        .catch(err => {
            // setLoading(false)
            // setErrors({Password: err?.response?.data?.message, Username: 'Try again'})
            console.log(err?.response?.data?.message)
        })
    }

    return allow && (
        <Grid 
            id='login-page' 
            container 
            alignContent='center' 
            style={{minHeight:'100vh'}}
        >
            <QubAccess title='Sign in' subtitle='Use your credentials to sign in' image={<QubIcon style={{ width:120 }} localIcon='bigoptibase-logo.png'/>} containerMaxWidth='xs'>
                <form onSubmit={handleSubmit(onSubmit)} >
                    <Grid container direction='column' spacing={3}>
                    <Grid item>
                        <Controller
                        name="Username"
                        control={control}
                        defaultValue=""
                        render={({ field }) => <TextField variant='outlined' label="Email" fullWidth inputProps={{...field}} />}
                        />
                    </Grid>
                    <Grid item>
                        <Controller
                        name="Password"
                        control={control}
                        defaultValue=""
                        render={({ field }) => <QubControls.Password label='Password' variant='outlined' fullWidth inputProps={{...field}} />}
                        />
                    </Grid>
                    <Grid item container justifyContent='flex-end' style={{marginTop: 20}}>
                        <Button type='submit' >
                            Login
                        </Button>
                    </Grid>
                    </Grid>
                </form>
            </QubAccess>
        </Grid>
    )
}

export default Login;
