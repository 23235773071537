import { Grid,makeStyles } from "@material-ui/core";





const useStyles = makeStyles({

    root:{
        display: 'flex'
    },
    grid:{
    display: 'flex',
    'flex-direction': 'column',
    alignItems: 'left',

    }

})



const DrawerComponent = (props) => {
    const classes = useStyles()


    return(
    

        <Grid
        variant="persistent"
        open = {true}
        anchor="left">
            <div className={classes.grid}>
                {props.children}
            
            </div>
        </Grid>


    )
}


export default DrawerComponent