import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useHistory } from "react-router";
import AuthError from "utils/AuthError";

const ProtectedRoute = ({path, component: Component, routeComponent: RouteComponent, render, isAuthenticated, ...rest}) => {

    const [allow, setAllow] = useState(null)
    const history = useHistory()

    useEffect(() => {
        isAuthenticated()
        .then(res => {
            setAllow(res)
        })
        .catch(err => {
            if(err instanceof AuthError )
                history.push('/login')
            else
                console.log(err)
        })
    }, [history, isAuthenticated])

    return allow && (
        <Route
          {...rest}
          render={props => {
            if (!allow){
                console.log('not allowed')
                return (
                    <Redirect 
                        to={{
                            pathname: '/login',
                            state: { from: props.location }
                        }}
                    />
                );
            }
            else{
              return Component ? <Component {...props} /> : render(props);
            }
          }}
        />
    );
};

export default ProtectedRoute;