import React from 'react';
import { QubNavigation, QubLoading } from '@qubiteq/qublib';
import { useEffect } from 'react';
import { useState } from 'react';
import { getMenu } from 'Services/apis'
import { Button } from '@material-ui/core'
import QubAvatar from './QubAvatar';

const Navigation = ({children, getUser, logout}) => {

    const [items, setItems] = useState(null)
    const [user, setUser] = useState(null)
    const [loadingItems, setLoadingItems] = useState(false);
    const [loadingUser, setLoadingUser] = useState(false)

    useEffect(() => {
        setLoadingItems(true)
        getMenu()
        .then(data =>{
            setItems(data)
            setLoadingItems(false)
        })
        .catch(err => {
            console.log(err)
            setLoadingItems(false)
        })

        setLoadingUser(true)
        getUser()
        .then(user => {
            setUser(user)
            setLoadingUser(false)
        })
        .catch(err => {
            console.log(err)
            setLoadingUser(false)
        })
    }, [getUser])

    if(loadingUser || loadingItems){
        return <QubLoading />
    }
    else if(items && user){ 
        return (
            <>
                <QubNavigation 
                    type="Sidebar"
                    permanent={false}
                    items={items}
                >
                    <div style={{flex:1}}/>
                    {user && <QubAvatar user={user} logout={logout}/>}

                </QubNavigation>
                {children}
            </>
        )
    }
    else {
        return (    
            <>
                <QubNavigation  
                    type="Navigation"
                    items={[]}
                >
                    <div style={{flex: 1}} />
                    <Button variant="contained" color="primary" href='/login'>
                        Login
                    </Button>
                </QubNavigation>
            </>
        )
    }
    
}

export default Navigation;