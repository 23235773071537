import axios from 'axios'

const axiosConfig = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_BASE_URL,
  })

const bdaConfig = axios.create({
  baseURL: "https://iccs-bigdata.cslab.ece.ntua.gr:9999/api",
  withCredentials:true

})

const bdaAuth = axios.create({
  withCredentials: true,
  baseURL: "https://iccs-bigdata.cslab.ece.ntua.gr:8443/auth/realms/test_realm/protocol/openid-connect"

})


export {axiosConfig,bdaConfig,bdaAuth}
