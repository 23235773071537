import Chart from "react-google-charts";
import React, { Suspense, lazy } from 'react'
import {Paper} from '@material-ui/core'
import {QubLoading} from '@qubiteq/qublib'
import LazyLoad from "react-lazyload";

const RecyclableChart = (props) => {

    const {
        type,
        data,
        makis,
        ...others
    } = props

    
    
    return (
        <Paper elevation = {3} style={{backgroundColor:'#f1f1f1',display:'flex',marginLeft:'1vw',justifyContent:'flex-start',height:'60vh',width:'90%', borderRadius:'6px',marginTop:'4vh' , boxShadow: '2px 2px 2px 2px #888888',marginRight:'2vh'}} >
            <LazyLoad style={{height:'99%',width:'99%',marginTop:'1%'}}>
            <Chart style={{back:'#0000ff',height:'99%',width:'98%'}}
                chartType= {type}
                data = {data}
                options={ type !== 'BarChart' ? {
                            title: props.title ,
                            legend: {position:'none'},
                            backgroundColor:'#f1f1f0',
                            // legend: {position:'none'},
                            hAxis: {
                                slantedText:false
                                }
                            } : 
                            {title: props.title ,
                            backgroundColor:'#f1f1f0'
                            }
                        
                            
                        }
                
                {...others}
                loader={<QubLoading/>}
            />
            </LazyLoad>
            
        </Paper> )
}




export default RecyclableChart