import React, { Component } from "react";
import Chart from "react-google-charts";
import {Grid,Paper,Button,Box, IconButton, Typography} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'


const SingleChart = () => {

    return ( 
      <Paper elevation = {3} style = {{overflowY:'unset' ,backgroundColor:'#414a4c',display:'flex',justifyContent:'center',alignContent:'center',alignItems:'center'}}>
        <Chart 
          style = {{width:'99%',height:'100%'}}
          chartType="LineChart"
          loader={<div>Loading Chart</div>}
          data={[
            ['Age', 'Weight'],
            [8, 12],
            [4, 5.5],
            [11, 14],
            [4, 5],
            [3, 3.5],
            [6.5, 7],
          ]}
          options={{
            title: 'Age vs. Weight comparison',
            titleTextStyle: {
                color: '#F6F6F6'
            },
            hAxis: { title: 'Age', minValue: 0, maxValue: 15,titleTextStyle:{color:'#6D9886'} ,textStyle:{color:'#A6F0C6'}},
            vAxis: { title: 'Weight', minValue: 0,titleTextStyle:{color:'#6D9886'}, maxValue: 15,textStyle:{color:'#A6F0C6'} },
            legend: 'none',
            'chartArea': {'width':'85%','height':'70%'},
            backgroundColor : '#414a4c',
            series: { 0 : {color: '#6E85B2'}}
          }}
        />
</Paper>
    )
}



const MultipleCharts = () => {

  return (
    <Grid container spacing = {1} style = {{display:'flex',justifyContent:'flex-start',padding:10}}>
      <Grid item  xs={12} md = {6}  xs={12} md = {6}  lg ={3}>
        <Paper elevation = {3} style = {{height:'97%',width:'99%',padding:2}}>
          <Box container style={{display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#414a4c',minHeight:150,height:'100%'}}>
            <Button variant= 'outlined' style={{backgroundColor:'#fefefe',marginRight:10}}>Create Custom Chart</Button>
            <Button variant = 'outlined' style={{backgroundColor:'#fefefe'}}>Add Saved Chart</Button>
        </Box>
        </Paper>
        
      </Grid>
      <Grid item xs={12} md = {6}  xs={12} md = {6}  lg ={3} >
        <SingleChart/>
      </Grid>
      <Grid item  xs={12} md = {6}  lg ={3}  >
        <SingleChart/>
      </Grid> 
      <Grid item  xs={12} md = {6}  lg ={3}>
        <SingleChart/>
      </Grid> 
      <Grid item  xs={12} md = {6}  lg ={3} >
        <SingleChart/>
      </Grid>
      <Grid item  xs={12} md = {6}  lg ={3}  >
        <SingleChart/>
      </Grid> 
      <Grid item  xs={12} md = {6}  lg ={3}>
        <SingleChart/>
      </Grid> 
      <Grid item  xs={12} md = {6}  lg ={3} >
        <SingleChart/>
      </Grid>
      <Grid item  xs={12} md = {6}  lg ={3}  >
        <SingleChart/>
      </Grid> 
      <Grid item  xs={12} md = {6}  lg ={3}>
        <SingleChart/>
      </Grid> 
    </Grid>
  )
}


export default MultipleCharts