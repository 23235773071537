import React, { useState } from 'react'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const QubSnackbar = ({open, setOpen, details}) => {
    return(
        <Snackbar open={open} autoHideDuration={6000} onClose={() => {setOpen(false)}}>
            <Alert variant='filled' severity={details?.severity} onClose={() => {setOpen(false)}}>
                {details?.content}
            </Alert>
        </Snackbar>
    )
}

export default QubSnackbar;