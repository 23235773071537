import React from 'react';
import { Avatar } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import QubUserPopper from './QubPopper';
import { Grid, Typography, Paper } from '@material-ui/core';
import { logout } from 'Services/apis';
import { useHistory } from 'react-router';

const UserAvatars = ({ user }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    
    const history = useHistory();

    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget)
        setOpen(prev => (!prev))
    }

    const onLogout = () => {
        logout()
        .then(_ => {
            history.push('/')
        })
        .catch(err => {
            console.log(err)
        })
    }

    return (
        <>
            <Avatar alt='User' style={{color: theme.palette.primary}}>
                <Button onClick={handleOpen}>
                    {user.username.charAt(0)}
                </Button>
            </Avatar>
            <QubUserPopper open={open} anchorEl={anchorEl}>
                <Paper elevation={3}>
                    <Grid container direction='column' style={{width: 150, marginTop: 14 , height:150}} >
                        <Grid item container direction='column' >
                            <Typography color='textPrimary' align='center'>
                                {user.firstName}
                            </Typography>
                            <Typography color='textPrimary' align='center'>
                                {user.lastName}
                            </Typography>
                        </Grid>
                        <div style={{flex:1}} />
                        <Grid item container >
                            <Button style={{width:'100%'}} onClick={onLogout}>
                                Logout
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </QubUserPopper>
        </>
    )
}

export default UserAvatars;