import { Typography,Grid,Button } from '@material-ui/core';
import React,{useState,useEffect} from  'react'
import { set } from 'react-hook-form';
import { ReadJobResult } from 'Services/apis';


const ApiCalls = ({testToken,insertMsgBDA,fetchMessages}) => {


const dummyData = [
    {
        "tuple": [
            {
                "key": "antenna_id",
                "value": "1"
            },
            {
                "key": "message_type",
                "value": "temperature"
            },
            {
                "key": "payload",
                "value": "any data"
            }
        ]
    },
    {
        "tuple": [
            {
                "key": "antenna_id",
                "value": "1"
            },
            {
                "key": "message_type",
                "value": "temperature"
            },
            {
                "key": "payload",
                "value": "any data"
            }
        ]
    },
    {
        "tuple": [
            {
                "key": "antenna_id",
                "value": "1"
            },
            {
                "key": "message_type",
                "value": "temperature"
            },
            {
                "key": "payload",
                "value": "any data"
            }
        ]
    }
]


    const [token,setToken] = useState('');
    const [insertMsg,setInsertMsg] = useState('')
    const [fetchMessage,setFetchMessage] = useState('')
    const [filterMessages,setFilterMessages] = useState('')
    const [filterAndFetchMasterData,setFilterAndFetchMasterData] = useState('')
    const [readJobResult,setReadJobResult] = useState('')

    const insertMsgExample = {
        "entries":[
                {
                "key":"antenna_id",
                "value":"1"
        },{
                "key":"message_type",
                "value":"temperature"
        },{
                "key":"payload",
                "value":"any data"
        }],
        "nested":[]
    }
    

    useEffect(() => {
        
        

        testToken()
            .then((res)=> {
                console.log("token",res)
                localStorage.setItem('bdaToken', res?.data?.access_token)
                setToken(res?.data)
            })
            .catch((err)=>{
                console.log("Token")
                console.log(err)
            })
        
    
       
        // fetchMessages('dtable',paramsFFMD)
        //     .then((res)=> {
        //         console.log(res)
        //         setFilterAndFetchMasterData(res.data)
        //     })
        //     .catch((err)=>{
        //         console.log("fetchMessages : dtable")
        //         console.log(err)
        //     })        

    },[])

        

        const paramsFFMD = new URLSearchParams()
        paramsFFMD.append('tableName','antennas')
        paramsFFMD.append('filters','id:1')

    const insertMessage = () => {
        insertMsgBDA(insertMsgExample)
            .then((res)=> {
                console.log("insertMsg",res)
                setInsertMsg(res)
            })
            .catch((err)=>{
                console.log("insertMsg")
                console.log(err)
            })

    }


    
    

    const readJob = () => {
        const paramsRJRB = new URLSearchParams()
        paramsRJRB.append('n','1')
        ReadJobResult(paramsRJRB)
            .then((res)=> {
                console.log("readJob",res)
                setReadJobResult(res)
            })
            .catch((err)=>{
                console.log("readJobResults",err)
            })
    }

    const handleFetchMessages = () => {
        const paramsFetchMsgs = new URLSearchParams()
        paramsFetchMsgs.append('type','rows')
        paramsFetchMsgs.append('n','3')
        fetchMessages('entries',paramsFetchMsgs)
            .then((res)=> {
                console.log("fetchMessages",res)
                setFetchMessage(res)
            })
            .catch((err)=>{
                console.log("fetchMessages",err)
            })

    }

    const handleFilterMessages = () => {
         const paramsFilterMsgs = new URLSearchParams()
         paramsFilterMsgs.append('filters','antenna_id:1;message_type:temperature')
         fetchMessages('select',paramsFilterMsgs)
            .then((res)=> {
                console.log("filterMessages",res)
                setFilterMessages(res)
            })
            .catch((err)=>{
                console.log("filterMessages",err)
            })

    }

    const handleFilterAndFetch = () => {
        
        fetchMessages('dtable',paramsFFMD)
            .then((res)=> {
                console.log("filterAndFetchMasterData",res)
                setFilterAndFetchMasterData(res)
            })
            .catch((err)=>{
                console.log("filterAndFetchMasterData",err)
            })    

    }


    return(
      <Grid container>
          <Grid item xs={12} md = {6} lg = {4} style = {{padding:40}}>
              <div style={{padding:20,borderStyle:'groove',borderRadius:'20px'}}>
              <Typography  variant='h4'>Token Response:</Typography>
              <Typography  style = {{color:'green',fontWeight:600,maxHeight:300,textOverflow:'ellipsis',overflowX:"hidden",marginTop:20,marginBottom:20}}>{ token && JSON.stringify(token,undefined,2)}</Typography>
              </div>
          </Grid>
          <Grid item xs={12} md = {6} lg = {4} style = {{padding:40}}>
          <div style={{padding:20,borderStyle:'groove',borderRadius:'20px'}}>
              <Typography variant='h4'>InsertMsg Response:</Typography>
              <Button variant='outlined' onClick = {insertMessage}>InsertMessage</Button>
              <Typography style = {{color:'green',fontWeight:600,maxHeight:300,textOverflow:'ellipsis',overflowX:"hidden",marginTop:20,marginBottom:20}}>{insertMsg && JSON.stringify(insertMsg,null,2)}</Typography>
              </div>
          </Grid>
          <Grid item xs={12} md = {6} lg = {4} style = {{padding:40}}>
          <div style={{padding:20,borderStyle:'groove',borderRadius:'20px'}}>
              <Typography variant='h4'>FetchMessages Response:</Typography>
              <Button variant='outlined' onClick = {handleFetchMessages}>FetchMessages</Button>
              <Typography style = {{color:'green',fontWeight:600,maxHeight:300,textOverflow:'ellipsis',overflowX:"hidden",marginTop:20,marginBottom:20}}>{fetchMessage && JSON.stringify(fetchMessage,null,2)}</Typography>
              </div>
          </Grid>
          <Grid item xs={12} md = {6} lg = {4} style = {{marginTop:60,padding:40}}>
          <div style={{padding:20,borderStyle:'groove',borderRadius:'20px'}}>
              <Typography variant='h4'>FilterMessages Response:</Typography>
              <Button variant='outlined' onClick = {handleFilterMessages}>FilterMessages</Button>
              <Typography style = {{color:'green',fontWeight:600,maxHeight:300,textOverflow:'ellipsis',overflowX:"hidden",marginTop:20,marginBottom:20}}>{filterMessages && JSON.stringify(filterMessages,undefined,2)}</Typography>
              </div>        
          </Grid>
          <Grid item xs={12} md = {6} lg = {4} style = {{marginTop:60,padding:40}}>
          <div style={{padding:20,borderStyle:'groove',borderRadius:'20px'}}>
              <Typography variant='h4'>FilterAndFetchMasterData Response:</Typography>
              <Button variant='outlined' onClick = {handleFilterAndFetch}>FilterAndFetchMasterData</Button>
              <Typography style = {{color:'green',fontWeight:600,maxHeight:300,textOverflow:'ellipsis',overflowX:"hidden",marginTop:20,marginBottom:20}}>{filterAndFetchMasterData && JSON.stringify(filterAndFetchMasterData,null,2)}</Typography>
              </div>
          </Grid>
          <Grid item xs={12} md = {6} lg = {4} style = {{marginTop:60,padding:40}}>
          <div style={{padding:20,borderStyle:'groove',borderRadius:'20px'}}>
              <Typography variant='h4'>Read Job Result from BDA Response:</Typography>
              <Button variant = 'outlined' onClick = {readJob}>ReadJobResult</Button>
              <Typography style = {{color:'green',fontWeight:600,maxHeight:300,textOverflow:'ellipsis',overflowX:"hidden",marginTop:20,marginBottom:20}}>{readJobResult && JSON.stringify(readJobResult,null,2)}</Typography>
              </div>
          </Grid>
      </Grid>
    );
  
    }

export default ApiCalls