import RecyclableChart from "./ReChart";
import React,{useState,useEffect,useRef} from  'react'
import useSnack from '../QubSnackbar/useSnack'
import QubSnackbar from "components/QubSnackbar/QubSnackbar";
import {Grid,Button,Typography,FormControl,InputLabel,Select,makeStyles,Box} from "@material-ui/core";
import Dropdown from "components/CustomInput/Dropdown";
import { useForm } from "react-hook-form";
import Multiselect from "components/CustomInput/Multiselect";

const diagrams = [
    {
        id: 1,
        name: "One Cell Multiple Parameters"
    },
    {
        id: 2,
        name: "One Parameter Multiple Cells"
    }
]



//const useStyles = makeStyles((theme) =>({}))




const ComboChart = (props) => {
    const { snack, ...topping} = useSnack();
    
    const { getData, getParams } = props;
    const chartForm = useForm();
    const feature = useRef();
    const baseStation = useRef();
    const cells = useRef();
    const params = useRef();
    cells.current = chartForm.watch("cells","")
    params.current = chartForm.watch("params")
    
    feature.current= chartForm.watch("feature","")
    baseStation.current = chartForm.watch("baseStations","")


    //const classes = useStyles(true);


    
    const [chart,setChart] = useState('AreaChart')
    const [chartData,setChartData ] = useState ([])
    const [chartReady,setChartReady] = useState(false)
    const [loading,setLoading] = useState(null)
    const [data,setData] = useState(null)
    const [firstStep,setFirstStep] = useState(true)
    const [showEvery,setShowEvery] = useState(1)
    const [chartRequest,setChartRequest] = useState({})



    const handleChange = (event) => {               //Chart Type
        const name = event.target.value;
        console.log(name)
        setChart(name)
        
    }

    useEffect(() => {
        getParams()
        .then(data => {
            console.log(data)
            setData(data)})
            
        .catch(error => console.log(error))
         

    },[]
    );




    // useEffect(()=>{
    //     let tempData = []
    //     chartData.forEach((dataset)=>{
    //         let tempDataset = [];
    //         dataset.data.map(({dVal,xVal}) => tempDataset.push([xVal,dVal]))
    //         tempDataset.unshift(["Year","Max Measurement of the Day"])
    //         tempData.push({chartName:dataset.chartName,data:tempDataset})
            
    //         })
    //         setFinalChartData([...tempData])

    //         // const indexOfLastChart = currentPage * chartsPerPage;
    //         // const indexOfFirstChart = indexOfLastChart - chartsPerPage
    //         // setCurrentData(finalData.slice(indexOfFirstChart,indexOfLastChart))
        

    // },[chartData])
    console.log(showEvery)

    const handleBack = () => {
        chartForm.reset()
        setFirstStep(true)
    }


    const validateNext = async () => {
        const featureValidate = await chartForm.trigger("feature")
        const dataValidate = await chartForm.trigger("chartType")
        if (featureValidate && dataValidate )
            setFirstStep(false)
    }

    const validateSubmit = async (submitData) => {
        if (feature.current === 1 ) {
            const cellValidate = await chartForm.trigger("cells")
            if (params?.current.length < 1){
                console.log("error")
                snack('Select at least 1 parameter.', 'error')
            }
            if (cellValidate && params?.current.length >=1)
                console.log(submitData)
                var parameters = params.current.join(", ")
                var arrayParams = params.current.sort();
                const req = {"chartType":"999","params": parameters , cells: cells.current}
                console.log(req)
                setChartRequest(req)
                getData(req)
                    .then((res)=> {
                        var results = res.map((obj)=> [obj.dt,obj.values.map((val)=> val)])
                        var header = [].concat.apply([],arrayParams.map(par => data["params"].filter(item => item.id === par).map(item => item.name)))
                        header.unshift("Date")
                        console.log(header)
                        var formatedResults = [header]
                        results.forEach((obj)=> {
                            obj[1].unshift(obj[0].replace('T',' '))
                            formatedResults.push(obj[1])
                        })
                        console.log(formatedResults)
                        setChartData(formatedResults)
                        setChartReady(true)
                        setShowEvery(formatedResults.length/8)
                    })
                    .catch((err)=> {
                        console.log(err)
                        snack("Error. This cell does not contain data.",'error')
                    })
        }

        else if (feature.current === 2) {
            const paramValidate = await chartForm.trigger("params")
            if (cells?.current.length < 1){
                console.log("error")
                snack('Select at least 1 cell.', 'error')
            }

            if (paramValidate && cells?.current.length >=1)
                console.log(submitData)
                var requestCells = cells.current.join(", ")
                var arrayCells = cells.current.sort()
                const req = {"chartType":"998","params" : params.current , "cells" : requestCells}
                console.log(req)
                getData(req)
                    .then((res)=> {
                        //console.log(res)
                        var results = res.map((obj)=> [obj.dt,obj.values.map((val)=> val)])
                        var header = [].concat.apply([],arrayCells.map(par => data["cells"].filter(item => item.id === par).map(item => item.name.substring(0,12))))
                        header.unshift("Date")
                        var formatedResults = [header]
                        results.forEach((obj)=> {
                            obj[1].unshift(obj[0].substring(0,10))
                            formatedResults.push(obj[1])
                        })
                        console.log(formatedResults)
                        setChartData(formatedResults)
                        setChartReady(true)
                        setShowEvery(formatedResults.length/8)
                    })
                    .catch((err)=> {
                        console.log(err)
                        snack('Error, if you had checked 3G Cells and changed parameter to Cell Throughput, you have to go back and make sure to select only 4G Cells','error')
                    })
                
        }
    }




    
    

    return(
        



        

        chartReady && chartData ?

        (!loading) ?
        <div style ={{display:'flex',flexDirection:'column'}}>
        <FormControl style = {{marginLeft:'1%',height:'15%',maxWidth:200}}>
                            <InputLabel>Visual Type</InputLabel>
                            <Select
                                native
                                value={chart}
                                onChange={handleChange}
                                inputProps={{
                                    name: 'Chart Type'
                                }}
                                >
                                        <option value='LineChart'>LineChart</option>
                                        <option value='BarChart'>BarChart</option>
                                        <option value='ColumnChart'>ColumnChart</option>
                                        <option value='ScatterChart'>ScatterChart</option>
                                        <option value='AreaChart'>AreaChart</option>
                                
                            </Select>
                        </FormControl>
        <RecyclableChart
            type = {chart}
            //data = {finalChartData[0].data}
            data = {chartData}
            options = { {
                // seriesType: 'steppedArea',
                // series: {1 : {type : 'line'} ,2: {type: 'area' } },
                interpolateNulls: true,
                backgroundColor:'#f1f1f0',
                hAxis : {
                    showTextEvery: parseInt(showEvery)
                }
                
            }
        }
        >


       


        </RecyclableChart>
            <Box style={{display:'flex',justifyContent:'flex-end',marginRight:'9%',marginTop:'1%'}}>
            <Button align='flex-end' variant='outlined' onClick={(e)=>console.log(chartRequest)}>Save Chart</Button>
            </Box>
        </div>
         : <div>Wait</div>

        : 
        
            <Grid spacing={0} direction ='column' container style = {{display:'flex',justifyContent:'center'}}>
                <QubSnackbar {...topping}/>
                <Grid item xs = {12}>
                    <Typography align='center'>Choose options for Custom Diagram</Typography>
                </Grid>

            {firstStep &&     
                <>
                
                <Grid item align='center' style={{paddingTop:30}}>
                    <Dropdown
                        name='feature'
                        options={diagrams}
                        rules={{required: 'You need to specify a feature'}}
                        label='Choose Feature'
                        valueField='id'
                        labelField='name'
                        control={chartForm.control}
                        hasValueField
                        setValue = {chartForm.setValue}
                        textFieldProps={{
                            required:true
                        }}
                        />
                </Grid>



            {data &&
                <Grid item align='center' style={{paddingTop:30}} >
                    <Dropdown
                        name = 'chartType'
                        rules={{required: 'You need to specify the type of data.'}}
                        options = {data?.chartTypes}
                        label= 'Choose Data'
                        valueField='id'
                        labelField='name'
                        setValue = {chartForm.setValue}
                        hasValueField
                        control={chartForm.control}
                        
                    />
                </Grid>   }
                </>
                }    
            
                
            
             {feature?.current === 1 && !firstStep && data && 
                <>
                    <Grid item align='center' style={{paddingTop:30}}>
                        <Dropdown
                            name= 'baseStations'
                            options = {data["baseStations"]}
                            rules={{required: 'You need to specify the base station.'}}
                            label = 'Choose Base station'
                            valueField = 'id'
                            hasValueField
                            labelField='name'
                            control={chartForm.control}
                            setValue = {chartForm.setValue}
                        />
                    </Grid>
                    

                    {baseStation.current && 
                    <>
                        <Grid item align='center' style={{paddingTop:30}}>
                            <Dropdown
                                name ='cells'
                                rules={{required: 'You need to specify the cell.'}}
                                options = {data["cells"].filter(cell => cell.basestationId === baseStation.current)}
                                label = 'Choose Cell'
                                valueField = 'id'
                                hasValueField
                                labelField ='name'
                                control = {chartForm.control}
                                setValue = {chartForm.setValue}
                            />
                        </Grid>
                        <Grid item align= 'center' style = {{paddingTop:30}}>
                            <Multiselect
                                name= 'params'
                                options = {baseStation.current === 1 ? data["params"].filter(param => param.id !== 3 && param.id !== 4) : data["params"]}
                                label = 'Choose Parameters'
                                valueField='id'
                                labelField='name'
                                hasValueField
                                onUse = {(feature?.current === 1)}
                                control = {chartForm.control}
                                setValue = {chartForm.setValue}
                                getValues = {chartForm.getValues}
                            />
                            </Grid>
                        </>
                        }

                
                    </> }
                {feature?.current === 2  && !firstStep &&  data && 
                <>
                    
                        <Grid item align='center' style={{paddingTop:30}}>
                            <Dropdown

                                name ='params'
                                options= {data["params"]}
                                rules={{required: 'You need to specify the parameter.'}}
                                label = 'Choose Parameter'
                                valueField = 'id'
                                hasValueField
                                labelField ='name'
                                control = {chartForm.control}
                                setValue = {chartForm.setValue}
                            />
                        </Grid>
                        <Grid item align= 'center' style ={{paddingTop:30}}>
                            <Multiselect
                                name= 'cells'
                                options =  {(params.current===3 || params.current===4) ? data["cells"].filter(item => item.basestationId === 2) : data["cells"]}
                                rules={{required:'You need to specify at least one.'}}
                                label = 'Choose Cells'
                                valueField='id'
                                labelField='name'
                                onUse = {(feature?.current === 2)}
                                hasValueField
                                control = {chartForm.control}
                                setValue = {chartForm.setValue}
                                getValues = {chartForm.getValues}
                            />
                            </Grid>

                    </> }








                    <Grid item align='center' style={{paddingTop:30}}>
                        {firstStep && <Button onClick = {validateNext}>Next</Button>}
                        {!firstStep &&
                            <Box align='center' direction='row' style={{display:'flex',justifyContent:'center'}}>
                                <Button onClick = {handleBack}>Back</Button>
                                <Button onClick = {() => chartForm.handleSubmit(validateSubmit)()}>Go</Button>
                            </Box>
                        }
                    </Grid>
                
            
                
        </Grid>
            
            


    )
}

export default ComboChart
