import React from 'react';
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core';

const NotFound = () => {
    return(
        <Grid container direction='column' justifyContent='center' alignContent='center' style={{height: '100vh'}}>

            <Typography variant='h1' color='textPrimary' align='center'>
                404
            </Typography>
            <Typography variant='h6' color='textSecondary' align='center'>
                Not Found
            </Typography>
            <Typography variant='body1' color='textSecondary' align='center'>
                The requested resource could not be found
            </Typography>
        </Grid>
    )
}

export default NotFound;