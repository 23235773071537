import {axiosConfig} from './axiosConfig';
import AuthError from '../utils/AuthError'
import { bdaAuth } from './axiosConfig';
import { bdaConfig } from './axiosConfig';
import axios from 'axios';

export const genericGetApi = (dataKey, params, cancelToken) => {
    return axiosConfig.post('/getData', {
        dataKey: dataKey,
        params: params
        },
        {
            cancelToken: cancelToken?.token
        })
        .then(res => {
            if(res?.data?.error)
                throw res.data
            
            return res?.data
        })
        .catch(err => {
            throw (err.error ? err : err?.response?.data)
        })
}

export const authorizeBDA = () => {
    const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
    }
    const params = new URLSearchParams()
    params.append('grant_type','password')
    params.append('client_id','bda_client')
    params.append('client_secret','bda_secret')
    params.append('username','qubitec')
    params.append('password','3f6654a8b40cc84')
    return bdaAuth.post('/token',params,config)
        .then((res)=> {
            if(res?.data?.error)
                throw res.data
            return res
        })
        .catch((err)=>{
            throw (err.error ? err : err?.response?.data)
        })
}

export const insertMsgBDA = (body) => {
    const headers = {
        'Content-Type':'application/json',
        'Authorization' : 'bearer ' + localStorage.getItem('bdaToken')
    }
    console.log(body,headers)

    return bdaConfig.post('/datastore/bigoptibase',body,{headers})
        .then((res)=> {
            console.log(res)
            if(res?.data?.error)
                throw res
            
            return res
            
        })
        .catch((err)=> {
            console.log(err)
            throw (err.error ? err : err?.response?.data)
        })
    
}

export const fetchMessages= (type,params) => {
    const headers = {
        'Authorization' : 'bearer ' + localStorage.getItem('bdaToken')
    }
    return bdaConfig.get(`/datastore/bigoptibase/${type}`,{headers: headers, params : params })
        .then((res)=> {
            console.log(res)
            if(res?.data?.error)
                throw res.data
            
            return res
            
        })
        .catch((err)=> {
            console.log(err)
            throw (err.error ? err : err?.response?.data)
        })
    
}

export const ReadJobResult = (params) => {
    const headers = {
        'Authorization' : 'bearer ' + localStorage.getItem('bdaToken')
    }
    return bdaConfig.get('/kpi/bigoptibase/antenna_metric1/fetch', {headers: headers, params : {n : 1}})
        .then((res)=>{
            console.log(res)
            if(res?.data?.error)
                throw res.data
            return res
        })
        .catch((err)=> {
            console.log(err)
            throw (err.error ? err : err?.response?.data)
        })
    
}

export const genericSendApi = (dataKey, params, cancelToken) => {
    return axiosConfig.post('/sendData', {
        dataKey: dataKey,
        params: params
        },
        {
            cancelToken: cancelToken?.token
        })
        .then(res => {
            if(res?.data?.error)
                throw res.data
            
            return res?.data
        })
        .catch(err => {
            throw (err.error ? err : err?.response?.data)
        })
}

export const authApi = (action, path, params) => {
    switch(action){
        case 'get':
            return axiosConfig.get(`/Auth/${path}`, params)
            .then(res => {
                return res;
            })
            .catch(err => {
                throw err;
            })
        case 'post':
            return axiosConfig.post(`/Auth/${path}`, params)
            .then(res => {
                return res;
            })
            .catch(err => {
                throw err;
            })
        default:
            return axiosConfig.get(`/Auth/${path}`, params)
            .then(res => {
                return res;
            })
            .catch(err => {
                throw err;
            })
        
    }
    
}
export const dynamicFiltering = (params, cancelToken) => {
    return genericGetApi('APIDynamicFiltering', params, cancelToken)
        .then(data => {
            return data;
        })
        .catch(err => {
            throw err;
        })
}

export const getTableParams = (params, cancelToken) => {
    return genericGetApi('APIGenSearchTableGetParams', params, cancelToken)
        .then(data => {
            return data
        })
        .catch(err => {
            throw err;
        })
}

export const getFormParams = (params, cancelToken) => {
    return genericGetApi('APIGetDynamicFormParams', params, cancelToken)
        .then(data => {
            return data ? data: {fields: [], params: null}
        })
        .catch(err => {
            throw err;
        })
}

/* Bring Value returns promise always */
export const bringValue = (params, cancelToken) => {
    return genericGetApi('APIBringValue', params, cancelToken)
}

export const getOne = (params, cancelToken) => {
    return genericGetApi('APIDynamicFormGetOne', params, cancelToken)
        .then(data => {
            return data;
        })
        .catch(err => {
            throw err;
        })
}

export const isAuthenticated = () => {
    return authApi('get','TestMsg')
        .then(res => {
            return true
        })
        .catch(err =>{
            console.log('Authentication error, please try again!', err)
            throw new AuthError({
                message: false
            }, 401)
        })
}

export const login = (params) => {
    return authApi('post', 'Login', params)
        .then(res => {
            return res
        })
        .catch(err => {
            throw err;
        })
}

export const getMenu = () => {
    return genericGetApi('APIGetMenu')
        .then(data => {
            return data
        })
        .catch(err => {
            throw err
        })
}

export const getUser = () => {
    return authApi('get','GetUser')
    .then(res => {
        return res?.data
    })
    .catch(err =>{
        if(err?.response?.status === 401)
            console.log('Authentication error, please try again!', err)
        throw err
    })
}

export const logout = () => {
    return authApi('get', 'Logout')
    .then(res => {
        return res
    })
    .catch(err => {
        throw err;
    })
}

export const getChartParams = () => {
    return genericGetApi('APIGetChartParams')
        .then(res => {
            return res;
        })
        .catch(err => {
            throw err;
        })
}

export const getChartData = (params) => {
    return genericGetApi('APIGetChartData', params)
        .then(res => {
            return res
        })
        .catch(err => {
            return err
        })
}

export const commit = (action, params) => {
    console.log(params)
    switch(action){
        case 'create':
            return genericSendApi('APICreateEntry', params)
            .then(res => {
                console.log(res)
                return res
            })
            .catch(err => {
                return err
            })
        case 'update': 
            return genericSendApi('APIUpdateEntry', params)
            .then(res => {
                return res
            })
            .catch(err => {
                return err
            })
        case 'delete': 
            return genericSendApi('APIDeleteEntry', params)
            .then(res => {
                return res
            })
            .catch(err => {
                return err
            })
    }
}

const exportedObject = {
    genericGetApi,
    genericSendApi,
    dynamicFiltering,
    getTableParams,
    getFormParams,
    bringValue,
    getOne,
    isAuthenticated,
    login,
    getMenu,
    getUser,
    logout,
    getChartParams,
    getChartData,
    commit,
    authorizeBDA,
    insertMsgBDA,
    fetchMessages,
    ReadJobResult
}

export default exportedObject