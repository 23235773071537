import RecyclableChart from '../components/Charts/ReChart';
import {QubLoading} from '@qubiteq/qublib'
import { Button,Grid,ListItem,Checkbox,ListItemText,TextField,Typography,Box,FormControl,InputLabel,IconButton,Select} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import React, {useState} from 'react'
import { useEffect } from 'react';
import DrawerComponent from '../components/Charts/DrawerComponent';
import Group from '../components/Charts/Group';
import styles from '../components/Charts/ChartStyle'
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles= makeStyles(styles)

const ReChart = (props) => {
    const classes = useStyles(styles);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('lg'))
    
    const { getData, getParams } = props;
  
    const {
        type,
        options,
        ...others
    } = props


    const [filters,setFilters] = useState ({})    
    // const [currentData,setCurrentData] = useState (null)        
    const [data,setData] = useState([])


    const [dateFrom,setDateFrom] = useState('')
    const [dateTo,setDateTo] = useState('')
    const [chartType, setChartType] = React.useState(3)
    const [chart, setChart] = React.useState('ColumnChart')
    const [finalData,setFinalData] = React.useState([])
    const [checkedStateCat,setCheckedStateCat] = useState([])
    const [checkedStateParams,setCheckedStateParams] = useState([])
    const [checkedStateCells,setCheckedStateCells] = useState([])
    const [checkedStateCellTypes,setCheckedStateCellTypes] = useState([])
    const [checkedStateBaseStations,setCheckedStateBaseStations] = useState([])
    const [filterOpen,setfilterOpen] = useState(true)
    const [loading,setLoading] = useState(false)
    const [firstTime,setFirstTime] = useState (true)
    const [count,setCount] =  useState(0);

    // const chartsPerPage = 4;
    const handleChange = (event) => {               //Chart Type
        const name = event.target.value;
        console.log(name)
        setChart(name)
        
    }

    const handleChartTypeChange = (event) => {
        const id = event.target.value;
        console.log(id)
        setChartType(id)
    }

    const handleDateFrom = (date) => {              //Date From
        console.log(date.target.value)
        setDateFrom(date.target.value);
    }

    const handleDateTo = (date) => {                //Date to
        setDateTo(date.target.value);
    }

    const handleOnChangeCat = (position) => {                                   //Category Checkbox Handler
        const updatedCheckedState = checkedStateCat.map((item, index) =>
          index === position ? !item : item
        );
      
        setCheckedStateCat(updatedCheckedState);
    }

    const handleOnChangeParams = (position) => {                                //Parameters Checkbox Handler
        const updatedCheckedState = checkedStateParams.map((item, index) =>
          index === position ? !item : item
        );
      
        setCheckedStateParams(updatedCheckedState);
    }

    const handleOnChangeCells = (position) => {                                //Cells CheckBox Handelr
        const updatedCheckedState = checkedStateCells.map((item, index) =>
          index === position ? !item : item
        );
      
        setCheckedStateCells(updatedCheckedState);
    }
    const handleOnChangeCellTypes = (position) => {                           //CellType Checkbox Handler
        if (checkedStateCellTypes[0]){
            if (position) {
                const updatedCheckedState = checkedStateCellTypes.map((item, index) =>
                !item
                );
                setCheckedStateCellTypes(updatedCheckedState);
            }
            else {
                const updatedCheckedState = checkedStateCellTypes.map((item, index) =>
                index === position ? !item : item
                );
                setCheckedStateCellTypes(updatedCheckedState);
            }
            
        }
        else if (checkedStateCellTypes[1]) {
            if (!position) {
                const updatedCheckedState = checkedStateCellTypes.map((item, index) =>
                !item
                );
                setCheckedStateCellTypes(updatedCheckedState);
            }
            else {
                const updatedCheckedState = checkedStateCellTypes.map((item, index) =>
                index === position ? !item : item
                );
                setCheckedStateCellTypes(updatedCheckedState);
            
            }
        }

        else {
            const updatedCheckedState = checkedStateCellTypes.map((item, index) =>
            index === position ? !item : item
            );
            setCheckedStateCellTypes(updatedCheckedState);
        }

        checkedStateCells.forEach((state,index) => {
            if (!filters["cells"][index].name.includes(filters["cellTypes"][position].name) )
                checkedStateCells[index] = false;
        })
        
    }


    const handleOnChangeBaseStations = (position) => {                                      //BaseStation CheckBox handler
        if (checkedStateBaseStations[0]){
            if (position) {
                const updatedCheckedState = checkedStateBaseStations.map((item, index) =>
                !item
                );
                setCheckedStateBaseStations(updatedCheckedState);
            }
            else {
                const updatedCheckedState = checkedStateBaseStations.map((item, index) =>
                index === position ? !item : item
                );
                setCheckedStateBaseStations(updatedCheckedState);
            }
            
        }
        else if (checkedStateBaseStations[1]) {
            if (!position) {
                const updatedCheckedState = checkedStateBaseStations.map((item, index) =>
                !item
                );
                setCheckedStateBaseStations(updatedCheckedState);
            }
            else {
                const updatedCheckedState = checkedStateBaseStations.map((item, index) =>
                index === position ? !item : item
                );
                setCheckedStateBaseStations(updatedCheckedState);
            
            }
        }

        else {
            const updatedCheckedState = checkedStateBaseStations.map((item, index) =>
            index === position ? !item : item
            );
            setCheckedStateBaseStations(updatedCheckedState);
        }

        checkedStateCells.forEach((state,index) => {
            if (!filters["cells"][index].name.startsWith(filters["baseStations"][position].name) )
                checkedStateCells[index] = false;
        })
    }




    useEffect(() => {
        if (!firstTime) handleSubmit()
        else setFirstTime(false)
    },[chartType])

    

    useEffect(() => {
        getParams()
        .then(data => {
            setCheckedStateCat(Array(data["categories"].length).fill(false))
            setCheckedStateParams(Array(data["params"].length).fill(false))
            setCheckedStateCells(Array(data["cells"].length).fill(false))
            setCheckedStateCellTypes(Array(data["cellTypes"].length).fill(false))
            setCheckedStateBaseStations(Array(data["baseStations"].length).fill(false))
            setFilters(data)})
        .catch(error => console.log(error))

        // setLoading(true)
        // getData({
        //     dateFrom:"",
        //     dateTo: "",
        //     categories : "",
        //     params : "",
        //     cells : "",
        //     cellTypes: "",
        //     baseStations: "",
        //     chartType : 1
        //     })
        //     .then(data => {
                
        //         if (!data.error) {
        //             setData(data)                    
        //         }
        //         else (
        //             setData([])
                
        //         )
        //         setLoading(false)
        //     })
        //     .catch(error =>{
        //         console.log(error)
        //         setLoading(false)
        //     })

    },[]
    );

    useEffect(()=> {
        if (!matches) setfilterOpen(false)
        else setfilterOpen(true)
    },[matches])

    useEffect(()=> {
        let tempData = []
        data.forEach((dataset)=>{
            let tempDataset = [];
            dataset.data.map(({dVal,xVal}) => tempDataset.push([xVal,dVal]))
            tempDataset.unshift(["Year","Value"])
            tempData.push({chartName:dataset.chartName,data:tempDataset})
            
            })
            setFinalData([...tempData])
            if (!matches) setfilterOpen(false)
            // const indexOfLastChart = currentPage * chartsPerPage;
            // const indexOfFirstChart = indexOfLastChart - chartsPerPage
            // setCurrentData(finalData.slice(indexOfFirstChart,indexOfLastChart))
        },[data]);

        

        // const paginate = (event,page) => {
        //     setCurrentPage(page)
        //     window.scrollTo(0, 0)
        // }


        const handleSubmit = async () => {
            setCount(1)
            let tempCat = "";
            checkedStateCat.forEach((state,index)=>{if (state) tempCat += filters["categories"][index].id + ","})
            let tempParams = "";
            checkedStateParams.forEach((state,index)=>{if (state) tempParams += filters["params"][index].id + ","})
            let tempCells = "";
            checkedStateCells.forEach((state,index)=>{if (state) tempCells += filters["cells"][index].id + "," })
            let tempCellType = "";
            checkedStateCellTypes.forEach ((state,index) => {if (state) tempCellType += filters["cellTypes"][index].id + ',' })
            let tempBaseStations = "";
            checkedStateBaseStations.forEach ((state,index) => {if (state) tempBaseStations += filters["baseStations"][index].id + ','})
            const request = {
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                    categories : tempCat.slice(0, -1),
                    params : tempParams.slice(0,-1),
                    cells : tempCells.slice(0,-1),
                    cellTypes: tempCellType.slice(0,-1),
                    baseStations: tempBaseStations.slice(0,-1),
                    chartType : parseInt(chartType)
            }
            setFinalData([])
            setLoading(true)
            getData(request)
                .then(data => {
                    console.log(data)
                    if (!data.error) {
                        setData(data)                    
                    }
                    else (
                        setData([])
                    
                    )
                    setLoading(false)
                })
                .catch(error =>{
                    console.log(error)
                    setLoading(false)
                })
            
        }

    return data ? (
        <Grid container spacing={0} >
            
        {(filterOpen && !matches) &&   <Grid item xs={12} sm = {12} lg= {3}>
                {filterOpen &&
                    <DrawerComponent>
                        <Box style= {{display:'flex',direction:'row',justifyContent:'space-between'}}>
                            <Button 
                                color='primary' 
                                className = {classes.button}
                                variant='contained' 
                                onClick={handleSubmit}
                            >
                                Search
                            </Button>
                            {loading && <QubLoading />}
    
                    
                        <IconButton aria-label="delete" onClick={(e)=>setfilterOpen(!filterOpen)} style={{alignSelf:'flex-end'}}>
                            <CloseIcon/>
                        </IconButton>
                        </Box>
                        <div className={classes.datediv}>   
                            <TextField
                                id="dateFrom"
                                label="From"
                                type="date"
                                // defaultValue="2021-05-24"
                                className={classes.textField}
                                value = {dateFrom}
                                onChange = {handleDateFrom}
                                InputProps={{
                                    classes: { input: classes.pointer }
                                }}
                                InputLabelProps = {{shrink:true}}
                            />

                            <TextField
                                id="dateTo"
                                label="To"
                                type="date"
                                // defaultValue="2021-07-31"
                                className={classes.textField}
                                value = {dateTo}
                                onChange = {handleDateTo}
                                InputProps={{
                                    classes: { input: classes.pointer }
                                }}
                                InputLabelProps = {{shrink:true}}
                            />
                            </div>

                        <Group name='Category'>
                            {filters["categories"]?.map(({id,name},index) => (
                                <ListItem 
                                    key={id} 
                                    value={name} 
                                    className={classes.item}
                                    >
                                        <Checkbox 
                                            color = 'primary' 
                                            onChange={()=> handleOnChangeCat(index)} 
                                            checked={checkedStateCat[index]} 
                                            />
                                        <ListItemText  primary={name} />
                                </ListItem>
                            ))}
                        </Group>

                        <Group name="Cell Type">
                                {filters["cellTypes"]?.map(({id,name},index) => (
                                        <ListItem 
                                            key={id}
                                            value={name}
                                            className={classes.item}
                                            >
                                                <Checkbox 
                                                    color = 'primary' 
                                                    onChange={()=> handleOnChangeCellTypes(index)} 
                                                    checked={checkedStateCellTypes[index]} />
                                                <ListItemText primary={name}/>
                                        </ListItem>
                                ))}

                        </Group>
                        
                        <Group name="Base Station">
                                {filters["baseStations"]?.map(({id,name},index) => (
                                        <ListItem 
                                            key={id} 
                                            value={name} 
                                            className={classes.item}
                                            >
                                                <Checkbox 
                                                    color = 'primary' 
                                                    onChange={()=> handleOnChangeBaseStations(index)} 
                                                    checked={checkedStateBaseStations[index]} 
                                                />
                                                <ListItemText primary={name}/>
                                        </ListItem>
                                ))}
                        </Group>
                        

                        <Group name='Parameters' >
                            {filters["params"]?.map(({id,name},index) => (
                                <ListItem 
                                    key={id} 
                                    value={name} 
                                    className={classes.item}
                                    >
                                        <Checkbox 
                                            color = 'primary' 
                                            onChange={()=> handleOnChangeParams(index)} 
                                            checked={checkedStateParams[index]}
                                        />
                                        <ListItemText primary={name} />
                                </ListItem>
                            ))}
                        </Group>

                        <Group name='Cells'>
                            {filters["cells"]?.map(({id,name},index) => (
                                    <ListItem 
                                        key={id} 
                                        value={name} 
                                        className={classes.item}
                                        disabled = {
                                            (name.startsWith('02') && checkedStateBaseStations[0] && !checkedStateBaseStations[1])
                                            || (name.startsWith('01') && checkedStateBaseStations[1]  && !checkedStateBaseStations[0])
                                            || (name.includes('3G-UMTS') && !checkedStateCellTypes[0] && checkedStateCellTypes[1])
                                            || (name.includes('4G-LTE') && checkedStateCellTypes[0] && !checkedStateCellTypes[1])} 
                                        >
                                            <Checkbox
                                                disabled = {
                                                    (name.startsWith('02') && checkedStateBaseStations[0] && !checkedStateBaseStations[1])
                                                    || (name.startsWith('01') && checkedStateBaseStations[1]  && !checkedStateBaseStations[0])
                                                    || (name.includes('3G-UMTS') && !checkedStateCellTypes[0] && checkedStateCellTypes[1])
                                                    || (name.includes('4G-LTE') && checkedStateCellTypes[0] && !checkedStateCellTypes[1]  )}
                                                color = 'primary' 
                                                onChange={()=> handleOnChangeCells(index)} 
                                                checked={checkedStateCells[index]} 
                                                
                                            />
                                            <ListItemText    primary={name}/>
                                    </ListItem>
                            ))}
                        </Group>

                    </DrawerComponent>}
                    
                </Grid>}
                

            
                <Grid container item xs={12} sm = {filterOpen? 12: 12} lg = {filterOpen? 9 : 12} style = {{display:'flex',direction:'column'}}  >
                    
                    <Grid item container xs={12} sm = {12} lg={12} className={classes.chartype} direction = 'row'>
                        {!filterOpen && <Button color ='primary' style={{marginLeft:'4.6%'}}  variant = 'contained' onClick={(e)=>setfilterOpen(!filterOpen)}>Show Filters</Button>}
                      {filters["chartTypes"] ?  
                            <FormControl style = {{height:'20%',marginLeft:'2%'}}>
                            <InputLabel >Data Type</InputLabel>
                            <Select
                                native
                                value={chartType}
                                onChange={handleChartTypeChange}
                                inputProps={{
                                    name: 'Chart Type'
                                }}
                                >
                                    {filters["chartTypes"]?.map(({id,name},index) => (
                                        <option key={index} value={id}>{name}</option>
                                    ))}
                            </Select>
                        </FormControl>
                        : null }
                        

                        <FormControl style = {{marginLeft:'2%',height:'15%'}}>
                            <InputLabel>Chart</InputLabel>
                            <Select
                                native
                                value={chart}
                                onChange={handleChange}
                                inputProps={{
                                    name: 'Chart Type'
                                }}
                                >
                                        <option value='LineChart'>LineChart</option>
                                        <option value='BarChart'>BarChart</option>
                                        <option value='ColumnChart'>ColumnChart</option>
                                        <option value='ScatterChart'>ScatterChart</option>
                                        <option value='AreaChart'>AreaChart</option>
                                
                            </Select>
                        </FormControl>
                        
                        {(!finalData.length && !loading) && <Typography display="block" style = {{marginTop: '10vh'}} >{!count ? 'Select filters and click search to see the charts you need.' : 'No data match the criteria.' }</Typography>}
                        
                    
                    </Grid>
                    
                <Grid container item xs = {12} direction='column'  className={classes.gridLeft} >
                    
                    { (finalData.length !== 0) ?
                        finalData.map(({chartName,data}, row) => {
                            return(
                                // <LazyLoad>
                                <Box style = {{width:'100%'}} key={row}>
                                    <Grid item container xs={12} sm={12} justifyContent={filterOpen ? 'flex-start' : 'center'} lg={12}  className={classes.gridCenter}  >
                                        <RecyclableChart
                                            title={chartName}
                                            data={data}
                                            type={chart}
                                            {...others}
                                            />
                        
                                    </Grid>
                                    </Box>
                                // </LazyLoad>
                                );
                        }) 
                        : null
                    }
                            
                    
                    </Grid>
               
                    {/* <Grid item xs = {12} sm = {12} lg ={12} className={classes.gridLeft} >  
                        <Pagination count = {Math.ceil(finalData.length / 4)} onChange ={paginate} className={classes.pagination}></Pagination>
                    </Grid> */}
                </Grid>
                <Grid item xs={12} sm = {12} lg= {3}>
                {(filterOpen && matches) &&
                    <DrawerComponent>
                        <Box style= {{display:'flex',direction:'row',justifyContent:'space-between'}}>
                            <Button 
                                color='primary' 
                                className = {classes.button}
                                variant='contained' 
                                onClick={handleSubmit}
                            >
                                Search
                            </Button>
                            {loading && <QubLoading />}
    
                    
                        <IconButton aria-label="delete" onClick={(e)=>setfilterOpen(!filterOpen)} style={{alignSelf:'flex-end'}}>
                            <CloseIcon/>
                        </IconButton>
                        </Box>
                        <div className={classes.datediv}>   
                            <TextField
                                id="dateFrom"
                                label="From"
                                type="date"
                                // defaultValue="2021-05-24"
                                className={classes.textField}
                                value = {dateFrom}
                                onChange = {handleDateFrom}
                                InputProps={{
                                    classes: { input: classes.pointer }
                                }}
                                InputLabelProps = {{shrink:true}}
                            />

                            <TextField
                                id="dateTo"
                                label="To"
                                type="date"
                                // defaultValue="2021-07-31"
                                className={classes.textField}
                                value = {dateTo}
                                onChange = {handleDateTo}
                                InputProps={{
                                    classes: { input: classes.pointer }
                                }}
                                InputLabelProps = {{shrink:true}}
                            />
                            </div>

                        <Group name='Category'>
                            {filters["categories"]?.map(({id,name},index) => (
                                <ListItem 
                                    key={id} 
                                    value={name} 
                                    className={classes.item}
                                    >
                                        <Checkbox 
                                            color = 'primary' 
                                            onChange={()=> handleOnChangeCat(index)} 
                                            checked={checkedStateCat[index]} 
                                            />
                                        <ListItemText  primary={name} />
                                </ListItem>
                            ))}
                        </Group>

                        <Group name="Cell Type">
                                {filters["cellTypes"]?.map(({id,name},index) => (
                                        <ListItem 
                                            key={id}
                                            value={name}
                                            className={classes.item}
                                            >
                                                <Checkbox 
                                                    color = 'primary' 
                                                    onChange={()=> handleOnChangeCellTypes(index)} 
                                                    checked={checkedStateCellTypes[index]} />
                                                <ListItemText primary={name}/>
                                        </ListItem>
                                ))}

                        </Group>
                        
                        <Group name="Base Station">
                                {filters["baseStations"]?.map(({id,name},index) => (
                                        <ListItem 
                                            key={id} 
                                            value={name} 
                                            className={classes.item}
                                            >
                                                <Checkbox 
                                                    color = 'primary' 
                                                    onChange={()=> handleOnChangeBaseStations(index)} 
                                                    checked={checkedStateBaseStations[index]} 
                                                />
                                                <ListItemText primary={name}/>
                                        </ListItem>
                                ))}
                        </Group>
                        

                        <Group name='Parameters' >
                            {filters["params"]?.map(({id,name},index) => (
                                <ListItem 
                                    key={id} 
                                    value={name} 
                                    className={classes.item}
                                    >
                                        <Checkbox 
                                            color = 'primary' 
                                            onChange={()=> handleOnChangeParams(index)} 
                                            checked={checkedStateParams[index]}
                                        />
                                        <ListItemText primary={name} />
                                </ListItem>
                            ))}
                        </Group>

                        <Group name='Cells'>
                            {filters["cells"]?.map(({id,name},index) => (
                                    <ListItem 
                                        key={id} 
                                        value={name} 
                                        className={classes.item}
                                        disabled = {
                                            (name.startsWith('02') && checkedStateBaseStations[0] && !checkedStateBaseStations[1])
                                            || (name.startsWith('01') && checkedStateBaseStations[1]  && !checkedStateBaseStations[0])
                                            || (name.includes('3G-UMTS') && !checkedStateCellTypes[0] && checkedStateCellTypes[1])
                                            || (name.includes('4G-LTE') && checkedStateCellTypes[0] && !checkedStateCellTypes[1])} 
                                        >
                                            <Checkbox
                                                disabled = {
                                                    (name.startsWith('02') && checkedStateBaseStations[0] && !checkedStateBaseStations[1])
                                                    || (name.startsWith('01') && checkedStateBaseStations[1]  && !checkedStateBaseStations[0])
                                                    || (name.includes('3G-UMTS') && !checkedStateCellTypes[0] && checkedStateCellTypes[1])
                                                    || (name.includes('4G-LTE') && checkedStateCellTypes[0] && !checkedStateCellTypes[1]  )}
                                                color = 'primary' 
                                                onChange={()=> handleOnChangeCells(index)} 
                                                checked={checkedStateCells[index]} 
                                                
                                            />
                                            <ListItemText    primary={name}/>
                                    </ListItem>
                            ))}
                        </Group>

                    </DrawerComponent>}
                    
                </Grid>
                
            </Grid>
            
    ) : <QubLoading/>
    

}

export default ReChart


