import React, { useState } from 'react';

const useSnack = () => {
    const [open, setOpen] = useState(false);
    const [details, setDetails] = useState(null)

    const snack = (content, severity) => {
        setOpen(true);
        setDetails({content: content, severity: severity})
    }

    return {
        open,
        setOpen,
        details,
        snack
    }
}

export default useSnack;