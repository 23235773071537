import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import QubDynamicRouter from '../components/QubDynamicRouter'
import Login from '../screens/Login'
// import QubDataGrid from '../components/QubDevExpressGrid/Grid';
// import QubDynamicForm from '../components/QubDynamicForm/QubDynamicForm';
import {QubDynamicForm, QubDataGrid} from '@qubiteq/qublib'
import ProtectedRoute from './ProtectedRoute'
import { dynamicFiltering, getTableParams, getFormParams, bringValue, getOne, isAuthenticated, login, getUser, getChartParams, getChartData} from 'Services/apis';
import Navigation from 'components/Navigation';
import NotFound from 'components/NotFound'
import Home from 'screens/Home';
import Chart from 'screens/Chart'
import apis from 'Services/apis';
import ApiCalls from 'screens/ApiCalls';
import ComboChart from 'components/Charts/ComplexChart';
import MultipleCharts from '../screens/MultipleCharts'

const withNavigation = (Component) => (props) => (
  <>
    <Navigation getUser={getUser}/>
    <Component {...props} />
  </>
) 

export const withApis = (Component) => (props) => (
  <Component {...props} {...apis} />
)

const Router = () => {
    return (
        <BrowserRouter>
          <Switch>
            
            <Route path='/home' component={withNavigation(Home)} />
            <Route path="/dynamic/:component" component={QubDynamicRouter} />
            <Route path="/MultipleCharts" component={withNavigation(()=> <MultipleCharts/>)} />
            <Route path='/navigation' component={Navigation}/>
            <ProtectedRoute path="/bda" isAuthenticated={isAuthenticated} component={withNavigation(() => <ApiCalls testToken={apis.authorizeBDA} insertMsgBDA={apis.insertMsgBDA} fetchMessages={apis.fetchMessages}/> )} />
            <ProtectedRoute path="/custom-chart"  isAuthenticated={isAuthenticated} component={withNavigation(() => <ComboChart getParams={getChartParams} getData={getChartData}/>)}/>
            <ProtectedRoute path='/charts' isAuthenticated={isAuthenticated} component={withNavigation(() => <Chart getData={getChartData} getParams={getChartParams} /> )} />
            <ProtectedRoute 
              isAuthenticated={isAuthenticated} 
              path="/table/:dataKey" 
              component={withNavigation(() => 
                <QubDataGrid 
                  {...apis}
                />)}
            />
            
            <Route path="/error" component={NotFound} />
            <Route path="/dynformtest/:dataKey" component={() => <QubDynamicForm getFormParams={getFormParams} bringValue={bringValue} getOne={getOne} /> } />
            <Route path="/login" component={() => <Login login={login} isAuthenticated={isAuthenticated} home={'/home'} />} /> 
            <Redirect to='/home' />
          </Switch>
        </BrowserRouter>
    )
}

export default Router;