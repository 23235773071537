import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "90vh",
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    height: "90%"
  },
  image: {
    backgroundImage: "url('/bigoptibase-logo.png')", //url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    //backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

const Home = () => {
  const classes = useStyles();
  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={12} className={classes.image}></Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <h1>Υποσύστημα Οπτικοποίησης και Ενεργειακής Διαχείρισης</h1>
          <Divider />
          <p>
            Βελτιστοποίηση Κατανάλωσης Ενέργειας Σταθμών Βάσης με Τεχνολογίες
            Διαχείρισης Μεγάλου Όγκου Δεδομένων
          </p>
        </Paper>
      </Grid>
      
    </Grid>
  );
};

export default Home;
