const ChartStyle = {
    root: {
        flexGrow: 1,
      },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft:'2vh'
    },
    pointer: {
        fontSize: 14,
        "&::-webkit-calendar-picker-indicator": {
            cursor: "pointer"
        }
    },
    item: {
        backgroundColor:'#ffffff',
        padding: 0,              
        //  height: '15%',
        '& span, & svg': {
            fontSize: '1rem',
        }
    },
    gridCenter: {
        display:"flex",
    },
    datediv: {
        marginTop:'2vh',
        display:'flex',
        direction:'row',
        justifyContent:'flex-start'
    },
    button: {
        marginTop:'0.4vh',
        marginLeft:'2vh'
    },
    chartype: {
        display:'flex',
        justifyContent:"flex-start",
    },
    gridLeft: {
        display:'flex',
        justifyContent:'center'
    },
    pagination : {
        marginTop:'2vh',
        marginBottom:'4vh'
    }
}

export default ChartStyle