import React, { useEffect, useState } from "react";
import {Checkbox,FormControl,FormControlLabel,FormLabel, TextField} from "@material-ui/core";
import { Controller } from "react-hook-form";
import PropTypes from 'prop-types';

const Multiselect = ({name, control, setValue, getValues, label, options = [], valueField, labelField,onUse}) => {
  const defaultValue = getValues(name);
  const [selectedItems, setSelectedItems] = useState(defaultValue ? defaultValue : []);

  // we are handling the selection manually here
  const handleSelect = (value) => {
    const isPresent = selectedItems.indexOf(value);
    if (isPresent !== -1) {
      const remaining = selectedItems.filter((item) => item !== value);
      setSelectedItems(remaining);
    } else {
      setSelectedItems((prevItems) => [...prevItems, value]);
    }
  };

  // we are setting form value manually here
  useEffect(() => {
    setValue(name, selectedItems)
  }, [selectedItems, name, setValue]);

  return (
    <FormControl size={"small"} variant={"outlined"}>
      <FormLabel component="legend" style={{color:'#111',paddingBottom:15}}>{label}</FormLabel>
      <div style={{display: 'flex', flexDirection: 'column',maxHeight:400,overflowY:'scroll',border:'1px solid #999',borderRadius:'4px',padding:6}}>
        {options?.map((option, index) => {
          const isChecked = onUse ? selectedItems?.includes(option[valueField]) : null
          return (
            <React.Fragment key={option[valueField]}>
              <FormControlLabel
                control={
                  <Controller
                    name={name}
                    render={() => {
                      return (
                        <Checkbox
                          checked={isChecked}
                          onChange={() => handleSelect(option[valueField])}
                        />
                      );
                    }}
                    control={control}
                  />
                }
                label={option[labelField]}
                key={option[valueField]}
              />
              {isChecked && (option?.isText || option?.isBit || option?.isDate || option?.isInt || option?.isDecimal) &&
                <Controller
                  name={`${option.inputName}`}
                  control={control}
                  defaultValue=''
                  render={({ field }) => {
                    return (
                      <TextField
                        {...field}
                      />
                    )
                  }}
                />}
            </React.Fragment>
          );
        })}
      </div>
    </FormControl>
  );
};

Multiselect.propTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired
}

export default Multiselect;